import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";

const InConclusion = () => {
  return (
    <Box sx={{ backgroundColor: "#020202E5", width: "100vw" }}>
      <Grid
        container
        sx={{
          height: "auto",
          width: "100vw",
          backgroundColor: "#020202E5",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          padding: "11%",
        }}
      >
          
        <Grid
          item
          container
          sm={12}
          sx={{
            backgroundImage: 'url("../images/Smarter_faster.png")',
            backgroundColor:"#020202",
            backgroundSize: "cover",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            height:"auto",
            padding:"20px",
            border: "2px solid #3A3A3A"
          }}
        >
          <Grid item sm={12} sx={{ paddingTop: "5%",  marginBottom:"3%" }}>
            <img
              src="../images/whitelogo.svg"
              alt="kashika"
              style={{ width: "48px", height: "48px" }}
            />
          </Grid>
          <Grid item sm={12} sx={{   marginBottom:"3%" }}>
            <Typography
              sx={{
                color: "#ffffff",
                fontFamily: "Manrope",
                fontSize: "48px",
                fontWeight: 700,
                lineHeight: "30px",
                textAlign: "center",
                wordSpacing:"10px"
              }}
            >
              Smarter. Faster. 
            </Typography>
          </Grid>
          <Grid item sm={12} sx={{   marginBottom:"3%" }}>
            <Typography
              sx={{
                color: "#ffffff",
                fontFamily: "Manrope",
                fontSize: "20px",
                fontWeight: 400,
                lineHeight: "30px",
                textAlign: "center",
              }}
            >
              It's important to capture spontaneous metrics, create dashboards,
              and derive insights in order to make<br/> quick and informed decisions.
            </Typography>
          </Grid>
          <Grid item sm={12} sx={{   marginBottom:"3%" }}>
            <Button
              sx={{
                width: "150px",
                height: "45px",
                padding: "12px 17.6px 13px 24px",
                borderRadius: "8px",
                color: "#ffffff",
                backgroundColor: "#0000FF",
                marginBottom:"2%",
                textWrap:"nowrap",
                fontSize:"15px",
                textTransform: "none",
                '&:hover':{
                  backgroundColor:"#0F0FC5"
                }
              }}
            >
              
              Get Started 
              <img src="../images/arrowforward.svg" alt="getstarted" style={{ marginLeft: "8px", paddingBottom:"2px" }}/>
            </Button>
          </Grid>
        </Grid>
      </Grid>
     
    </Box>
  );
};

export default InConclusion;
