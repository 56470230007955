import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";

const DataAnalytics = () => {
  return (
    <Box sx={{ backgroundColor: "#020202E5", width: "100vw" }}>
      <Grid
        container
        sx={{
          height: "auto",
          width: "100vw",
          backgroundColor: "#020202E5",
          backgroundImage: 'url("../images/dataAnalyticsgrid.svg")',
          backgroundSize:"cover",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          padding: "20px",
          marginBottom: "50px",
        }}
      >
        <Grid
          item
          container
          sm={12}
          sx={{
            padding: "90px",
            paddingBottom: "60px",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
            <Grid item sm={12} sx={{ paddingTop: "30px" }}>
              <Typography
                sx={{
                  color: "#ffffff",
                  fontFamily: "Manrope",
                  fontSize: "64px",
                  fontWeight: 400,
                  lineHeight: "87.42px",
                  textAlign: "center",
                }}
              >
                Your Ultimate Data Analytics Platform<br/>
               <span
                style={{
                    color: "#0000FF",
                    fontFamily: "Manrope",
                    fontSize: "64px",
                    fontWeight: 400,
                    lineHeight: "87.42px",
                    textAlign: "center",
                  }}
              >
               No knowledge of SQL is necessary
               </span>
              </Typography>
            </Grid>
            <Grid item sm={12} sx={{ paddingTop: "30px" }}>
              <Typography
                 sx={{
                    color: "#ffffff",
                    fontFamily: "Manrope",
                    fontSize: "20px",
                    fontWeight: 400,
                    lineHeight: "35px",
                    textAlign: "center",
                  }}
              >
                No SQL or coding skills needed. Simply connect to your
                datasource and let our AI assistant handle the rest. Ask
                questions in<br/> natural language, share stunning charts with your
                team, and uncover insights from your data in no time
              </Typography>
            </Grid>
          </Grid>
      </Grid>
    </Box>
  );
};

export default DataAnalytics;
