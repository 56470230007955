import React from "react";
import "./styles/cardcontainer.css";
import { Box, Grid, Typography } from "@mui/material";

const CardContainer = () => {
  return (
    <Box sx={{ backgroundColor: "#020202E5", width: "100vw" }}>
      <Grid
        container
        sx={{
          height: "auto",
          width: "100vw",
          backgroundColor: "#020202E5",
          backgroundImage: 'url("../images/grid_lines.svg")',
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #3A3A3A",
            borderRadius: "16px",
            backgroundColor: "#020202",
            backgroundImage: 'url("../images/customised.png")',
            backgroundSize: "cover",
            height: "611px",
            margin: "20px",
            padding: "20px",
          }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="../images/reports.svg"
              alt="reports"
              style={{ width: "186.88px", height: "200.62px" }}
            />
            <Typography
              sx={{
                color: "#FFFFFF",
                fontFamily: "Manrope",
                fontSize: "26px",
                fontWeight: 700,
                lineHeight: "30px",
                paddingBottom: "10px",
                paddingTop: "70px",
              }}
            >
              CUSTOMIZED REPORTS
            </Typography>
            <Typography
              sx={{
                color: "#FFFFFF",
                fontFamily: "Manrope",
                fontSize: "22px",
                fontWeight: 500,
                lineHeight: "30px",
              }}
            >
              for Unique Needs
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #3A3A3A",
            borderRadius: "16px",
            backgroundColor: "#020202",
            backgroundImage: 'url("../images/ccustomized1.png")',
            backgroundSize: "cover",
            height: "611px",
            margin: "20px",
            padding: "20px",
          }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="../images/datasharing.svg"
              alt="datasharing"
              style={{
                width: "200.33px",
                height: "220.72px",
                marginTop: "20px",
              }}
            />
            <Typography
              sx={{
                color: "#FFFFFF",
                fontFamily: "Manrope",
                fontSize: "22px",
                fontWeight: 500,
                lineHeight: "30px",
                paddingTop: "50px",
                paddingBottom: "10px",
                textWrap: "wrap",
              }}
            >
              Enhance Collaboration with
            </Typography>
            <Typography
              sx={{
                color: "#FFFFFF",
                fontFamily: "Manrope",
                fontSize: "26px",
                fontWeight: 700,
                lineHeight: "30px",
                textWrap: "wrap",
              }}
            >
              REAL-TIME DATA SHARING
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #3A3A3A",
            borderRadius: "16px",
            backgroundColor: "#020202",
            backgroundImage: 'url("../images/customised3.png")',
            backgroundSize: "cover",
            height: "611px",
            margin: "20px",
            padding: "20px",
          }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="../images/security.svg"
              alt="security"
              style={{ width: "223px", height: "223px", paddingTop: "50px" }}
            />
            <Typography
              sx={{
                color: "#FFFFFF",
                fontFamily: "Manrope",
                fontSize: "26px",
                fontWeight: 700,
                lineHeight: "30px",
                paddingBottom: "10px",
                paddingTop: "70px",
              }}
            >
              SECURITY and COMPLIANCE
            </Typography>
            <Typography
              sx={{
                color: "#FFFFFF",
                fontFamily: "Manrope",
                fontSize: "22px",
                fontWeight: 500,
                lineHeight: "30px",
              }}
            >
              at the Core
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CardContainer;
