import React from "react";
import { Box, Grid } from "@mui/material";

const AskKashika = () => {
  return (
    <Box sx={{ backgroundColor: "#020202E5", width: "100vw" }}>
      <Grid
        container
        sx={{
          height: "auto",
          width: "100vw",
          backgroundColor: "#020202E5",
          display: "flex",
          backgroundImage: 'url("../images/grid_lines.svg")',
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          marginBottom: "50px",
        }}
      >
        <Grid
          item
          sm={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="../images/kashikauiimg.svg"
            alt="askkashikaimg"
            style={{ width: "90%" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AskKashika;
