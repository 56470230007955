import "./footer.css";
import {useNavigate,Link} from "react-router-dom"

function Footer() {
  const Navigate = useNavigate();
  return (
    <div className="footer-section">
      <div className="footer-main">
        <img src="../images/logo.svg" alt="logo/" />
        <div className="footer-grid">
      <Link to="/"><p>Home</p></Link>    
          <p>Privacy Policy</p>
          <Link to="/contact"> <p>Contact us</p></Link>
          <p>Terms & Conditions</p>
        </div>
      </div>
    </div>
  );
}
export default Footer;
