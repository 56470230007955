import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Navbar";
import HomePage from "./Homepage";

import Contact from "./Contact";
import Footer from "./Footer";
function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <div className="content">
          
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<HomePage />} />{" "}
          
          </Routes>
        </div>
        <Footer/>
      </Router>
    </div>
  );
}
export default App;
