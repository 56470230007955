import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";

const GetStarted = () => {
  return (
    <Box sx={{ backgroundColor: "#020202E5", width: "100vw" }}>
      <Grid
        container
        sx={{
          height: "auto",
          width: "100vw",
          backgroundColor: "#020202E5",
          backgroundImage: 'url("../images/grid_lines.svg")',
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          padding: "20px",
          paddingTop:"6%",
          marginBottom: "50px",
        }}
      >
        <Grid
          item
          container
          sm={12}
          sx={{
            padding: "90px",
            paddingBottom: "120px",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Grid item sm={12}>
            <Typography
              sx={{
                color: "#ffffff",
                fontFamily: "Manrope",
                fontSize: "56px",
                fontWeight: 400,
                lineHeight: "72px",
                textAlign: "center",
              }}
            >
              “Instantly create KPIs with our intuitive chat interface -<br />
              <span
                style={{
                  color: "#ffffff",
                  fontFamily: "Manrope",
                  fontSize: "56px",
                  fontWeight: 600,
                  lineHeight: "72px",
                  textAlign: "center",
                }}
              >
                no more hours, just seconds!
              </span>
              ”
            </Typography>
            <Grid item sm={12} sx={{ paddingTop: "30px" }}>
              <img
                src="../images/kashikatext.svg"
                alt="kashika"
                style={{ width: "271.34px", height: "59.13px" }}
              />
            </Grid>
            <Grid item sm={12} sx={{ paddingTop: "30px" }}>
              <Typography
                sx={{
                  color: "#ffffff",
                  fontFamily: "Manrope",
                  fontSize: "20px",
                  fontWeight: 400,
                  lineHeight: "36px",
                  textAlign: "center",
                }}
              >
                “Unlock the ultimate AI-driven experience to query, visualize,
                and interact with your data. craft queries and design dashboards
                to monitor your key metrics effortlessly.”
              </Typography>
            </Grid>
            <Grid item sm={12} sx={{ paddingTop: "70px" }}>
              <Button
                sx={{
                  width: "150px",
                  height: "45px",
                  padding: "12px 17.6px 13px 24px",
                  borderRadius: "8px",
                  color: "#ffffff",
                  backgroundColor: "#0000FF",
                  textWrap:"nowrap",
                fontSize:"15px",
                textTransform: "none",
                '&:hover':{
                  backgroundColor: "#0F0FC5",
                }
                }}
              >
                {" "}
                Get Started
              <img src="../images/arrowforward.svg" alt="getstarted" style={{ marginLeft: "8px", paddingBottom:"2px" }}/>

              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GetStarted;
