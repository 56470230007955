import React, { useRef } from 'react';
import './styles/faq.css';

const AccordionItem = (props) => {
    const contentEl = useRef();
    const { handleToggle, active, faq = {} } = props; // Default to empty object if faq is undefined
    const { header = '', id, text = '' } = faq;

    // Function to handle click on the arrow
    const handleArrowClick = (event) => {
        event.stopPropagation(); // Prevent event from bubbling up to the header
        handleToggle(id); // Call the toggle function
    };

    return (
        <div className="rc-accordion-card">
                <div 
                    className={`rc-accordion-toggle p-4 ${active === id ? 'active' : ''}`}
                >
                    <h5 className="rc-accordion-title">{header}</h5>
                    <i 
                        className="fa fa-chevron-down rc-accordion-icon" 
                        onClick={handleArrowClick} // Add click handler to the arrow
                    ></i>
                </div>
            <div 
                ref={contentEl} 
                className={`rc-collapse ${active === id ? 'show' : ''}`} 
                style={
                    active === id
                        ? { height: contentEl.current.scrollHeight }
                        : { height: "0px" }
                }
            >
                <div className="rc-accordion-body">
                    <p className='mb-0'>{text}</p>
                </div>
            </div>
        </div>
    );
};

export default AccordionItem;
