import React from "react";
import './styles/technology.css';

function Technology() {
  const containerStyle = {
    backgroundImage: 'url("/images/technolog_bg.png")',
    backgroundPosition: 'center', 
    backgroundSize: 'cover', 
    paddingTop:'10%',
    paddingBottom:"8%"
  };
  return (
    <div className="technology-section" id="technology" style={containerStyle}>
    {/* <div className="technology-section" id="technology"> */}
       <div className="technology-text-content">
        <h3 className="technology-title">
          <span> Technology is only valuable if it drives actionable insights for
          your business.</span>
        </h3>
        <p className="technology-description">
        Generative AI is currently very popular, but both technologists and businesses are finding it challenging to effectively implement it. 
        That's where we come in to provide assistance
        </p>
      </div>
      
      <div className="technology-image-content">
        <img src= "/images/technology.png" alt="Doctor Group" className="technology-image1" />
      </div>

    </div>
  );
}

export default Technology;