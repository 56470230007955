import React from "react";
import { useState } from "react";
import faqs from "./utils/accordion_data";
import AccordionItem from "./AccordionItem";


const FAQ = () => {
    const [active, setActive] = useState(null);
  
    const handleToggle = (index) => {
      setActive(active === index ? null : index);
    };
  
    console.log(faqs);
    const containerStyle = {
        backgroundImage: 'url("/images/Frequently asked.png")',
        backgroundPosition: 'center', 
        backgroundRepeat: 'no-repeat', 
      };
    return (
      <div className="container-fluid mb-5 MuiGrid-root MuiGrid-item css-13i4rnv-MuiGrid-root" style={containerStyle}>
        <div className="row justify-content-center">
          <div className="col-md-8 mt-2">
            <div className="card-item">
              <div className="card-body">
                <h4 className="form-heading mb-4 text-primary text-center mt-3">Frequently Asked Questions</h4>
                {faqs.map((faq, index) => {
                  console.log(faq);
                  return (
                    <AccordionItem
                      key={index}
                      active={active}
                      handleToggle={handleToggle}
                      faq={faq}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  export default FAQ;