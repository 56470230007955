import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Root = styled("div")(({ theme }) => ({
  flexGrow: 1,
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#020202",
  height: "7.5rem",
  width:"100%",
  display: "flex",
  paddingLeft: "4%",
  justifyContent: "center",
  position: "fixed",
  zIndex: "1000",
  [theme.breakpoints.up("md")]: {
    paddingRight: "35%",
  },
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  alignItems: "center",
}));

const NavItems = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  flexGrow: 1,
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const ListWrapper = styled("div")(({ theme }) => ({
  width: 250,
}));

const Logo = styled("img")(({ theme }) => ({
  height: 40,
  marginRight: theme.spacing(2),
}));

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeItem, setActiveItem] = useState("");

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleNavClick = (item) => {
    setActiveItem(item);
    setDrawerOpen(false); // Close drawer on item click
  };

  const drawerList = () => (
    <ListWrapper
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {["Home", "Contact Us"].map((text) => (
          <ListItem
            button
            key={text}
            component={NavLink}
            to={text === "Home" ? "/home" : "/contact"}
            onClick={() => handleNavClick(text)}
          >
            <ListItemText
              primary={text}
              style={{ fontWeight: activeItem === text ? "bold" : "normal" }}
            />
          </ListItem>
        ))}
      </List>
    </ListWrapper>
  );

  return (
    <Root>
      <StyledAppBar position="fixed">
        <Toolbar>
          <Title variant="h6">
            <Logo src="../images/logo.svg" alt="Logo" />
          </Title>
          <NavItems>
            <Button
              color="inherit"
              component={NavLink}
              to="/home"
              onClick={() => handleNavClick("Home")}
              style={{ fontWeight: activeItem === "Home" ? "bold" : "normal" }}
            >
              Home
            </Button>
            <Button
              color="inherit"
              component={NavLink}
              to="/contact"
              onClick={() => handleNavClick("Contact Us")}
              style={{
                fontWeight: activeItem === "Contact Us" ? "bold" : "normal",
              }}
            >
              Contact Us
            </Button>
          </NavItems>
          <MenuButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </MenuButton>
        </Toolbar>
      </StyledAppBar>
      <Drawer anchor="top" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerList()}
      </Drawer>
    </Root>
  );
};

export default Navbar;
