import React from "react";
import { Box, Grid } from "@mui/material";
import AskKashika from "./AskKashikaContainer";
import CardContainer from "./CardContainer";
import DataAnalytics from "./DataAnalyticsContainer";
import GetStarted from "./GetStartedContainer";
import InConclusion from "./InConclusion";
import FAQ from "./FAQ";
import Technology from "./Technology"
const HomePage = () => {
  return (
    <Box sx={{ backgroundColor: "#020202E5", width: "100vw" }}>
      <Grid container sx={{
        backgroundColor: "#020202E5",
      }}>
        <GetStarted/>
        <DataAnalytics/>
        <Technology/>
      <AskKashika/>
      <CardContainer/>
      <InConclusion/>
      <FAQ/>
      </Grid>
   
    </Box>
  );
};

export default HomePage;
