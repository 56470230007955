import "./contact.css";
import Footer from "./Footer";

function Contact() {
  return (
    <div className="contact-page">
      <div className="contact-section">
        <div className="contact-box1">
          <div className="address-div">
            <p>ADDRESS</p>
            <p>
              {" "}
              Call or email us at anytime; we strive to respond to all in
              quiries within 24 hours on business days. We will be happy to
              answer any questions you may have
            </p>
          </div>

          <div className="box1-items">
            <img
              src="../images/location-icon.png"
              alt="location icon"
              className="contactpage-icons"
            />
            <p>
              5th floor, ASG Heights, opposite YSR Cricket Stadium, Vasundhara
              Nagar, Pothinamallayya Palem, Visakhapatnam, Andhra Pradesh 530041
            </p>
          </div>
          <div className="box2-items">
            <img
              src="../images/phone-icon.png"
              alt="phone icon"
              className="contactpage-icons"
            />
            
              <p>081434 64464</p>
            
          </div>
          <div className="box2-items">
            <img
              src="../images/globe-icon.png"
              alt="globe icon"
              className="contactpage-icons"
            />
            <p>
              <a href="https://inndata.in" target="none">
                https://inndata.in
              </a>
            </p>
          </div>
        </div>
        <div className="contact-box2">
          <input type="name" placeholder="Name" />
          <input type="email" placeholder="Email Address" />
          <input type="mobile" placeholder="Mobile" />
          <input type="text" placeholder="Message" />
          <button>
            <img src="../images/Vector.png" alt="send logo/" />
            Send Message
          </button>
        </div>
      </div>
    </div>
  );
}

export default Contact;
