const faqs = [
    {
        id: 1,
        header:"Who is Kashi-ka for?",
        text: `"Kashika is for everyone in your organization,whether you're in the executive suite,management, or any other role.It's crafted to
        eliminate the communication barrier between data analysts and team members without  technical expertise.With just basic skills to connect 
        to a data source, anyone can use kashika independently and effectively."`
    },
    {
        id: 2,
        header:"How does Kashi-ka work?",
        text: `"Connect your datasource and start a natural language conversation with the Kashika AI assistant about your data.
        You can save the generated charts for your dashboards or view the results directly in the new chat window" `
    },
    {
        id: 3,
        header:"Is AI SQL Generation Accurate?",
        text: `"Yes,AI chart generation is highly accurate.Our AI assistant uses description of your schema tables and columns as context to 
        improve query accuracy significantly. We leverage top LLM models to ensure precision and reliability in the results." `
    },
    {
        id: 4,
        header:"What kind of Data can i connect?",
        text: `"Kashika currently supports .CSV,.xlsx, .txt, and .pdf formats, with more options coming soon."`
    },
    {
        id: 5,
        header:"How will Kashi-ka use my data?",
        text: `"We proritize data privacy. Your data is never stored in our database or used to train any LLM. All data is stored locally on
        your machines,ensuring complete privacy and security."`
    },
    {
        id: 6,
        header:"I have more questions, who can talk to?",
        text: `You can reach out to us at enquiry@kashi-ka.ai.`
    }

]
export default faqs;